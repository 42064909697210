import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import FlowCenterComponent from './flow-center';
import FlowEntrustComponent from '@/components/flow-manage/flow-entrust/index.vue';
import { FlowCenterService } from '@/sevices';
import badgeCustom from '@/components/badge-custom/index.vue';
let FlowCenter = class FlowCenter extends Vue {
    constructor() {
        super(...arguments);
        this.todoTypeOption = [
            { name: '待办', value: 1 },
            { name: '待阅', value: 2 },
            { name: '已办', value: 3 },
            { name: '已阅', value: 4 },
            { name: '流程委托', value: 5 },
        ];
        this.selectedValue = [];
        this.notitle = '';
        this.searchNum = {};
        this.badgeStyle = {
            backgroundColor: 'rgb(255 182 179 / 80%)',
            border: '1px solid #F0353F',
            marginLeft: '16px',
            color: 'rgb(240 53 63 / 70%)',
        };
    }
    async toggleMenu(menu) {
        this.searchCount();
        this.$router.push({
            name: 'flow-center',
            query: {
                todoType: menu.key,
            },
        }).catch(() => { });
    }
    async searchCount() {
        const res = await FlowCenterService.searchCount();
        if (res.success) {
            this.searchNum = res.data;
        }
    }
    created() {
        this.selectedValue = [Number(this.$route.query.todoType) || 1];
        this.searchCount();
    }
};
FlowCenter = __decorate([
    Component({
        name: 'FlowCenter',
        components: {
            FlowCenterComponent,
            FlowEntrustComponent,
            badgeCustom,
        },
    })
], FlowCenter);
export default FlowCenter;
