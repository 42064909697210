import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let badgeCustom = class badgeCustom extends Vue {
    get title() {
        if (!this.options.showTitle)
            return '';
        return this.options.num;
    }
};
__decorate([
    Prop({ default: () => { } })
], badgeCustom.prototype, "badgeStyle", void 0);
__decorate([
    Prop({
        default: () => {
            return {
                showTitle: true,
                num: 1,
                overflowNum: 99,
                showZero: false,
            };
        },
    })
], badgeCustom.prototype, "options", void 0);
badgeCustom = __decorate([
    Component({
        name: 'badgeCustom',
        components: {},
    })
], badgeCustom);
export default badgeCustom;
