import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, Ref, Emit, } from 'vue-property-decorator';
import { FlowCenterService } from '@/sevices';
let SetField = class SetField extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.submiting = false;
        this.fieldColumns = [];
    }
    get filterColumns() {
        const columns = this.columns.filter((item) => item.key !== 'index');
        const disabledSetFieldColumns = columns.filter((item) => item.disabledSetField);
        const enabledSetFieldColumns = columns.filter((item) => !item.disabledSetField);
        return [...enabledSetFieldColumns, ...disabledSetFieldColumns];
    }
    get checkedAll() {
        return this.fieldColumns.every((item) => {
            return item.checked;
        });
    }
    get indeterminate() {
        let res = false;
        if (this.fieldColumns.length) {
            if (!this.checkedAll) {
                res = this.fieldColumns.some((item) => item.checked);
            }
        }
        return res;
    }
    setColumns() {
        return this.filterColumns.map((item) => {
            const hiddenField = this.hiddenField.length
                ? this.hiddenField
                : this.defaultHideFieldList;
            if (!hiddenField.includes(item.key)) {
                this.$set(item, 'checked', true);
            }
            else {
                this.$set(item, 'checked', false);
            }
            return item;
        });
    }
    get todoType() {
        return Number(this.$route.query.todoType) || 1;
    }
    checkAll(e) {
        this.fieldColumns.forEach((item) => {
            if (!item.disabledSetField) {
                item.checked = e.target.checked;
            }
        });
    }
    reload() { }
    async comfirm() {
        this.submiting = true;
        const hideFields = this.fieldColumns
            .filter((item) => {
            return !item.checked;
        })
            .map((item) => {
            return item.key;
        });
        if (!hideFields.length) {
            hideFields.push('noHide');
        }
        const res = await FlowCenterService.saveHideField({
            hideFields: hideFields,
            todoType: this.todoType,
        });
        if (res.success) {
            this.$message.success('保存成功');
            this.cancel();
            this.reload();
        }
        this.submiting = false;
    }
    cancel() {
        this.$emit('input', false);
    }
    onChangeValue(v) {
        this.visible = v;
        if (v) {
            this.fieldColumns = this.setColumns();
        }
    }
};
__decorate([
    Prop()
], SetField.prototype, "value", void 0);
__decorate([
    Prop({ default: () => [] })
], SetField.prototype, "columns", void 0);
__decorate([
    Prop({ default: () => [] })
], SetField.prototype, "hiddenField", void 0);
__decorate([
    Prop({ default: () => [] })
], SetField.prototype, "defaultHideFieldList", void 0);
__decorate([
    Ref()
], SetField.prototype, "cform", void 0);
__decorate([
    Emit('reload')
], SetField.prototype, "reload", null);
__decorate([
    Watch('value')
], SetField.prototype, "onChangeValue", null);
SetField = __decorate([
    Component({
        name: 'SetField',
    })
], SetField);
export default SetField;
