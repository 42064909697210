import { __decorate } from "tslib";
import { Component, Emit, Prop, Ref, Vue, Watch, } from 'vue-property-decorator';
import moment from 'moment';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import { FlowService } from '@/sevices';
import { NodeType } from '@/typings/flow-manage';
import { Form, FormItem } from '@/components/shared/CForm/index';
import TreeUtils from '@/common/TreeUtils';
import { UnitType } from '../../../typings/organization/org-manage';
let CreateEntrust = class CreateEntrust extends Vue {
    constructor() {
        super(...arguments);
        // 标题
        this.modalTitle = '新建委托';
        this.visible = false;
        this.isEditWorkflowRange = false;
        this.pageLoading = false;
        this.confirmLoading = false;
        this.endOpen = false;
        this.userInfo = {};
        this.curUserOrgInfo = {};
        this.workflowRangeTreeData = [];
        this.staffOptions = {
            selectDept: false,
            multiple: false,
        };
        this.flowTypeList = [
            {
                value: 1,
                name: '流程审批',
            },
            {
                value: 2,
                name: '流程发起',
            },
        ];
        this.treeExpandedKeys = [];
        this.form = {
            principal: [],
            entrustedPerson: [],
            entrustType: undefined,
            rangeDate: [],
            formRangeDate: {
                startValue: null,
                endValue: null,
            },
            workflowRangeType: 1,
            workflowRange: [],
            workflowRangeId: [],
        };
        this.rules = {
            principal: [
                { required: true, message: '委托人不能为空', trigger: 'change' },
            ],
            entrustedPerson: [
                { required: true, message: '被委托人不能为空', trigger: 'change' },
            ],
            entrustType: [
                { required: true, message: '委托类型不能为空', trigger: 'change' },
            ],
            rangeDate: [
                { required: true, message: '受托期限不能为空', trigger: 'change' },
            ],
            workflowRangeType: [
                { required: true, message: '流程范围不能为空', trigger: 'change' },
            ],
            workflowRange: [
                { required: true, message: '部分流程不能为空', trigger: 'change' },
            ],
        };
    }
    reload() { }
    get expired() {
        return (this.editData && this.editData.expired) || false;
    }
    // 必须要选择了委托人才可以选择流程范围
    get isSelectWorkflowRangeType() {
        return !!this.form.principal.length;
    }
    get excludeUserIds() {
        return this.form.principal.length && this.form.principal[0].id;
    }
    onFormRangeDateChange(date) {
        this.form.rangeDate = [];
        for (const value of Object.values(date)) {
            if (value) {
                this.form.rangeDate.push(value);
            }
        }
    }
    onChangeRangeId() {
        if (this.operateType === 1) {
            this.form.workflowRange = [];
            this.addWorkflowToRange();
        }
    }
    async onChangeRangeType(v) {
        if (v === 1) {
            this.isEditWorkflowRange = false;
        }
        if (v === 2 && this.form.principal.length) {
            if (!this.workflowRangeTreeData.length) {
                this.workflowRangeTreeData = await this.getTree(NodeType.App);
                this.readonlyTreeSelect();
            }
        }
    }
    treeExpand(expandedKeys = []) {
        this.treeExpandedKeys = expandedKeys;
    }
    /**
     * 提示
     */
    showWorkflowRangeTypeTip() {
        if (!this.isSelectWorkflowRangeType) {
            this.$message.info('请先选择委托人');
        }
    }
    onChangePrincipal(principal = []) {
        if (!principal.length) {
            this.form.workflowRangeType = 1;
            this.form.workflowRange = [];
            this.form.workflowRangeId = [];
            this.form.entrustedPerson = [];
        }
        this.workflowRangeTreeData = [];
        this.treeExpandedKeys = [];
    }
    /**
     * 设置组件只能读，不能搜索
     */
    readonlyTreeSelect() {
        this.$nextTick(() => {
            if (!this.treeSelect)
                return;
            const input = this.treeSelect.$el.querySelector('.ant-select-search__field');
            if (!input)
                return;
            input.setAttribute('readonly', '');
        });
    }
    disabledStartDate(startValue) {
        const prevDate = moment().subtract(1, 'days');
        if (startValue.valueOf() < prevDate.valueOf()) {
            return true;
        }
        const endValue = this.form.formRangeDate.endValue;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    }
    disabledEndDate(endValue) {
        const prevDate = moment().subtract(1, 'days');
        if (endValue.valueOf() < prevDate.valueOf()) {
            return true;
        }
        const startValue = this.form.formRangeDate.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return startValue.valueOf() >= endValue.valueOf();
    }
    handleStartOpenChange(open) {
        if (!open) {
            this.endOpen = true;
        }
    }
    handleEndOpenChange(open) {
        this.endOpen = open;
    }
    editWorkflowRange() {
        this.isEditWorkflowRange = true;
        this.readonlyTreeSelect();
    }
    closeWorkflowRange() {
        this.isEditWorkflowRange = false;
        this.form.workflowRangeId = [];
    }
    addWorkflowToRange() {
        for (const id of this.form.workflowRangeId) {
            const workflowRangeItem = this.form.workflowRange.find((item) => item.workflowCode === id);
            if (!workflowRangeItem) {
                const nodeState = TreeUtils.getTreeNodeStateByKey(id, 'code', this.workflowRangeTreeData);
                const node = nodeState.nodeState;
                const item = {};
                if (node) {
                    const codeArr = node.key.split('-');
                    item.appCode = codeArr[0];
                    item.workflowCode = codeArr[1];
                    item.workflowName = node.title;
                }
                this.form.workflowRange.push(item);
            }
        }
    }
    confirmWorkflowRange() {
        this.addWorkflowToRange();
        this.form.workflowRangeId = [];
        this.isEditWorkflowRange = false;
    }
    async onLoadTreeData(e) {
        const node = e.dataRef;
        let nextNodeType = '';
        if (e.expanded || node.children.length)
            return;
        if (node.nodeType === NodeType.App) {
            nextNodeType = NodeType.Flow;
        }
        node.children = await this.getTree(nextNodeType, node.code, node.key);
    }
    async getTree(type, code, parentKey) {
        let list = [];
        let dataList = [];
        if (type === NodeType.App) {
            const principal = this.form.principal[0] || {};
            const userId = principal.id;
            dataList = await this.getAppList({ userId: userId });
        }
        else if (type === NodeType.Flow) {
            dataList = await this.getFlowList({ appCode: code });
        }
        list = this.generateTree(type, dataList, parentKey);
        return list;
    }
    generateTree(type, dataList = [], parentKey = '') {
        const list = [];
        dataList.forEach((item) => {
            let code = '';
            let title = '';
            const keys = [];
            if (parentKey) {
                keys.push(parentKey);
            }
            if (type === NodeType.App) {
                code = item.appCode;
                title = item.appName;
            }
            if (type === NodeType.Flow) {
                code = item.workflowCode;
                title = item.workflowName;
            }
            keys.push(code);
            list.push({
                key: keys.join('-'),
                title: title,
                code: code,
                children: [],
                nodeType: type,
                value: code,
                isLeaf: type === NodeType.Flow,
                disabled: type !== NodeType.Flow,
            });
        });
        return list;
    }
    async getAppList(params) {
        const res = await FlowService.getAppList(params);
        if (res.success) {
            return res.data;
        }
        return [];
    }
    async getGroupList(params) {
        const res = await FlowService.getGroupList(params);
        if (res.success) {
            return res.data;
        }
        return [];
    }
    async getFlowList(params) {
        const res = await FlowService.getFlowList(params);
        if (res.success) {
            return res.data;
        }
        return [];
    }
    getRangeDate(date) {
        let beginMomentTime = '';
        let endMomentTime = '';
        if (date.length) {
            const beginTime = date[0] || {};
            const endTime = date[1] || {};
            beginMomentTime = moment(beginTime).format('YYYY-MM-DD HH:mm:ss');
            endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
            return {
                beginMomentTime,
                endMomentTime,
            };
        }
    }
    setRangeDate(date) {
        const beginMomentTime = moment(date.entrustStartTime, 'YYYY-MM-DD HH:mm:ss');
        const endMomentTime = moment(date.entrustEndTime, 'YYYY-MM-DD HH:mm:ss');
        return [beginMomentTime, endMomentTime];
    }
    onClose() {
        this.$emit('input', false);
    }
    async save() {
        if (this.operateType === 1 && this.form.workflowRangeType === 1) {
            this.form.workflowRange = [];
        }
        if (this.isEditWorkflowRange && this.operateType === 2) {
            this.$message.error('请单击部分流程的“确定/取消”按钮');
            return;
        }
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        if (this.form.rangeDate.length < 2) {
            this.$message.success('请选择开始日期/结束日期');
            return;
        }
        this.confirmLoading = true;
        if (this.operateType === 1) {
            await this.addEntrust();
        }
        else {
            await this.updateEntrust();
        }
    }
    forEachTree(workflowRangeTreeData, callback) {
        const forEachTree = (tree = []) => {
            tree.forEach((item) => {
                callback(item);
                if (item.children && item.children.length) {
                    forEachTree(item.children);
                }
            });
        };
        forEachTree(workflowRangeTreeData);
    }
    getWorkFlowName(id) {
        let name = '';
        this.forEachTree(this.workflowRangeTreeData, (item) => {
            if (item.code === id) {
                name = item.title;
            }
        });
        return name;
    }
    getWorkflowRange() {
        const workflowRange = this.form.workflowRangeType === 1 ? [] : this.form.workflowRange;
        return workflowRange;
    }
    getParams() {
        const date = this.getRangeDate(this.form.rangeDate);
        const params = {
            consignorId: this.form.principal[0].id,
            consignorName: this.form.principal[0].name,
            entrustEndTimeStr: date.endMomentTime,
            entrustStartTimeStr: date.beginMomentTime,
            entrustType: this.form.entrustType,
            mandataryId: this.form.entrustedPerson[0].id,
            mandataryName: this.form.entrustedPerson[0].name,
            workflowRangeType: this.form.workflowRangeType,
            workflowRange: this.getWorkflowRange(),
        };
        return params;
    }
    async addEntrust() {
        const params = this.getParams();
        FlowService.addEntrust(params)
            .then((res) => {
            if (res.success) {
                this.$message.success('添加成功');
                this.onClose();
                this.reload();
            }
        })
            .finally(() => {
            this.confirmLoading = false;
        });
    }
    async updateEntrust() {
        const params = this.getParams();
        params.id = this.editData.id;
        FlowService.updateEntrust(params)
            .then((res) => {
            if (res.success) {
                this.$message.success('更新成功');
                this.onClose();
                this.reload();
            }
        })
            .finally(() => {
            this.confirmLoading = false;
        });
    }
    resetForm() {
        this.isEditWorkflowRange = false;
        this.form.principal = [];
        this.form.entrustedPerson = [];
        this.form.entrustType = undefined;
        this.form.rangeDate = [];
        this.form.formRangeDate.startValue = null;
        this.form.formRangeDate.endValue = null;
        this.form.workflowRangeType = 1;
        this.form.workflowRangeId = [];
        this.form.workflowRange = [];
        this.treeExpandedKeys = [];
        this.workflowRangeTreeData = [];
    }
    removeItem(item) {
        const workflowRangeIndex = this.form.workflowRange.findIndex((workflowRangeItem) => {
            return workflowRangeItem.workflowCode === item.workflowCode;
        });
        this.form.workflowRange.splice(workflowRangeIndex, 1);
    }
    async onValueChange(v) {
        this.visible = v;
        if (v) {
            if (this.isFlowCenter) {
                const userInfo = JSON.parse(localStorage.getItem('user') || '{}')?.userInfo || {};
                this.form.principal = [
                    { id: userInfo.id, name: userInfo.name, type: 3 },
                ];
            }
            this.form.entrustType = 1;
            if (this.operateType === 2) {
                this.modalTitle = '编辑委托';
                this.pageLoading = true;
                this.pageLoading = false;
                const editData = this.editData;
                this.form.principal = [
                    { id: editData.consignorId, name: editData.consignorName, type: UnitType.User },
                ];
                this.form.entrustedPerson = [
                    { id: editData.mandataryId, name: editData.mandataryName, type: UnitType.User },
                ];
                this.form.entrustType = editData.entrustType;
                const date = this.setRangeDate({
                    entrustStartTime: editData.entrustStartTime,
                    entrustEndTime: editData.entrustEndTime,
                });
                this.form.rangeDate = date;
                this.form.formRangeDate = {
                    startValue: date[0],
                    endValue: date[1],
                };
                this.form.workflowRangeType = editData.workflowRangeType;
                this.form.workflowRangeId = [];
                this.form.workflowRange = editData.workflowRange || [];
            }
            else {
                this.modalTitle = '新建委托';
            }
        }
        else {
            this.resetForm();
        }
    }
};
__decorate([
    Prop()
], CreateEntrust.prototype, "value", void 0);
__decorate([
    Prop()
], CreateEntrust.prototype, "editData", void 0);
__decorate([
    Prop({ default: false })
], CreateEntrust.prototype, "isFlowCenter", void 0);
__decorate([
    Prop({ default: 1 })
], CreateEntrust.prototype, "operateType", void 0);
__decorate([
    Ref()
], CreateEntrust.prototype, "cform", void 0);
__decorate([
    Ref()
], CreateEntrust.prototype, "treeSelect", void 0);
__decorate([
    Emit('reload')
], CreateEntrust.prototype, "reload", null);
__decorate([
    Watch('form.formRangeDate', { deep: true, immediate: true })
], CreateEntrust.prototype, "onFormRangeDateChange", null);
__decorate([
    Watch('form.workflowRangeId')
], CreateEntrust.prototype, "onChangeRangeId", null);
__decorate([
    Watch('form.workflowRangeType', { immediate: true })
], CreateEntrust.prototype, "onChangeRangeType", null);
__decorate([
    Watch('value')
], CreateEntrust.prototype, "onValueChange", null);
CreateEntrust = __decorate([
    Component({
        name: 'CreateEntrust',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
        },
    })
], CreateEntrust);
export default CreateEntrust;
