import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Input } from '@h3/antd-vue';
import Uploader from './upload.vue';
let ApproveModal = class ApproveModal extends Vue {
    constructor() {
        super(...arguments);
        this.approval = '';
        this.resources = [];
        this.defaultUploadProps = {
            action: '/api/file/upload',
            directory: false,
            disabled: false,
            icon: {
                type: 'paper-clip',
                color: '#333333',
                size: '18px',
            },
            listType: 'text',
            maxLength: 10,
            maxLengthError: '文件最大可上传10M',
            multiple: true,
            showUploadList: true,
            uploadSize: '10',
            uploadText: '上传附件',
        };
    }
    /**
     * 上传了附件
     */
    onUpload(res) {
        if (res && res.status === 'done' && res.response.success) {
            const file = res.response.data;
            if (!file || !file.refId) {
                return;
            }
            const { refId, fileExtension, fileSize, name, } = file;
            this.resources.push({
                refId,
                fileExtension,
                fileSize,
                name,
            });
        }
        else if (res && res.status === 'removed' && res.response.success) {
            const file = res.response.data;
            if (!file || !file.refId) {
                return;
            }
            const { refId } = file;
            const removeFile = this.resources.findIndex((f) => f.refId === refId);
            this.resources.splice(removeFile, 1);
        }
    }
    submit() {
        const data = {
            content: this.approval,
            resources: this.resources,
        };
        return data;
    }
};
__decorate([
    Prop({ default: '' })
], ApproveModal.prototype, "title", void 0);
ApproveModal = __decorate([
    Component({
        name: 'ApproveModal',
        components: {
            AInput: Input,
            Uploader,
        },
    })
], ApproveModal);
export default ApproveModal;
