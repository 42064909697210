import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue, Ref, } from 'vue-property-decorator';
import { Modal } from '@h3/antd-vue';
import ApproveModal from './approve-modal.vue';
import { FlowCenterService } from '@/sevices';
import * as Utils from '@/common/utils';
let agreeFlow = class agreeFlow extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
    }
    /**
     * 确认回调
     */
    async handleOk() {
        this.isLoading = true;
        if (!this.appCode) {
            this.$message.warning('流程被撤回或作废，任务不存在');
            this.isLoading = false;
            this.$emit('update:isVisible', false);
            this.$emit('reload');
            return;
        }
        const submitInfo = this.Approve.submit();
        const params = {
            batch: {
                approval: submitInfo,
                workItemIds: this.flowList,
            },
            appCode: this.appCode,
        };
        FlowCenterService.batchSubmit(params).then(async (res) => {
            if (res.success) {
                if (res.data.failNum === 0) {
                    await Utils.sleep(1000);
                    this.$message.success('批量同意成功');
                    this.$emit('update:isVisible', false);
                    this.$emit('reload');
                }
                else {
                    this.$emit('update:isVisible', false);
                    this.$emit('hasFail', res.data);
                }
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }
    handleCancel() {
        this.isLoading = false;
        this.$emit('update:isVisible', false);
    }
};
__decorate([
    PropSync('isVisible', { default: false })
], agreeFlow.prototype, "visible", void 0);
__decorate([
    Ref()
], agreeFlow.prototype, "Approve", void 0);
__decorate([
    Prop({ default: () => ([]) })
], agreeFlow.prototype, "flowList", void 0);
__decorate([
    Prop({ default: '' })
], agreeFlow.prototype, "appCode", void 0);
agreeFlow = __decorate([
    Component({
        name: 'agreeFlow',
        components: {
            AModal: Modal,
            ApproveModal,
        },
    })
], agreeFlow);
export default agreeFlow;
