import { __decorate } from "tslib";
import { Component, Vue, Ref, Watch, } from 'vue-property-decorator';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { Form, FormItem } from '@/components/shared/CForm/index';
import SetField from './set-field.vue';
import { StaffSelector, CTable } from '@cloudpivot-hermes/common-components';
import { FlowCenterService, WorkPlatformService } from '@/sevices';
import agreeFlow from '@/components/flow-manage/flow-center/agree-flow.vue';
import agreeFlowFail from '@/components/flow-manage/flow-center/agree-flow-fail.vue';
import { service } from '@cloudpivot-hermes/nail-free-login';
import { isPhone } from '@/common/utils';
const Global = namespace('global');
let FlowCenterComponent = class FlowCenterComponent extends Vue {
    constructor() {
        super(...arguments);
        this.todoTypeOption = [
            { name: '待办', value: 1 },
            { name: '待阅', value: 2 },
            { name: '已办', value: 3 },
            { name: '已阅', value: 4 },
        ];
        this.flowStatus = [
            { label: '进行中', value: 1, enName: 'PROCESSING' },
            { label: '已完成', value: 2, enName: 'COMPLETED' },
            { label: '已作废', value: 3, enName: 'CANCELED' },
            { label: '流程异常', value: 4, enName: 'EXCEPTION' },
        ];
        this.form = {
            instanceName: '',
            moduleCode: undefined,
            originator: [],
            appCode: '',
            batchOperate: false,
            publishTime: [],
            instanceState: undefined,
            handlerPerson: [],
        };
        // 待办应用列表
        this.appList = [];
        // 显示待办弹窗
        this.showAgreeFlow = false;
        // 批量审批部分失败
        this.showAgreeFlowFail = false;
        this.failInfo = {};
        this.flowTemplate = [];
        this.isExpand = false;
        this.isShowSetField = false;
        this.tableList = [];
        /**
         * table列定义
         */
        this.columns = [
            {
                fixed: 'left',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                align: 'center',
                disabledSetField: true,
                slots: { title: 'index', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'instanceName',
                key: 'instanceName',
                width: 250,
                slots: { title: 'instanceName', name: '流程名称' },
                scopedSlots: { customRender: 'instanceName' },
            },
            {
                dataIndex: 'workflowName',
                key: 'workflowName',
                width: 250,
                disabledSetField: true,
                ellipsis: true,
                slots: { title: 'workflowName', name: '流程模板' },
            },
            {
                dataIndex: 'activityName',
                key: 'activityName',
                ellipsis: true,
                width: 172,
                slots: { title: 'activityName', name: '当前节点' },
            },
            {
                dataIndex: 'participantName',
                key: 'participantName',
                ellipsis: true,
                width: 100,
                slots: { title: 'participantName', name: '处理人' },
            },
            {
                dataIndex: 'usedtime',
                key: 'usedtime',
                ellipsis: true,
                width: 172,
                slots: { title: 'usedtime', name: '停留时间' },
                scopedSlots: { customRender: 'usedtime' },
            },
            {
                dataIndex: 'instanceState',
                key: 'instanceState',
                ellipsis: true,
                width: 120,
                slots: { title: 'instanceState', name: '流程状态' },
                scopedSlots: { customRender: 'instanceState' },
            },
            {
                dataIndex: 'originatorName',
                key: 'originatorName',
                ellipsis: true,
                disabledSetField: true,
                width: 100,
                slots: { title: 'originatorName', name: '发起人' },
            },
            {
                dataIndex: 'instanceStartTime',
                key: 'instanceStartTime',
                ellipsis: true,
                width: 182,
                slots: { title: 'instanceStartTime', name: '发起时间' },
            },
            {
                dataIndex: 'departmentPathName',
                key: 'departmentPathName',
                ellipsis: true,
                disabledSetField: true,
                slots: { title: 'departmentPathName', name: '发起组织' },
            },
        ];
        this.hideFieldList = [];
        this.fieldColumns = [];
        this.defaultHideFieldList = ['participantName', 'baomidengji'];
        this.checkData = [];
    }
    get wrapperTableHeight() {
        return this.wrapperTable.offsetHeight;
    }
    get tableColumns() {
        const columns = JSON.parse(JSON.stringify(this.columns));
        return this.todoType === 1 || this.todoType === 3
            ? columns
            : columns.filter((item) => item.key !== 'instanceState');
    }
    get isShowFlowStatausFormItem() {
        return this.todoType === 3;
    }
    get todoType() {
        return Number(this.$route.query.todoType) || 1;
    }
    get IsDingTalk() {
        return sessionStorage.IS_DINGTALK === 'true';
    }
    get isFreeLoginPlatform() {
        return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
    }
    get ISWECHAT() {
        return sessionStorage.IS_WECHAT === 'true';
    }
    async onTodoTypeChange() {
        this.getFlowTemplate();
        this.setColumn();
        await this.search();
    }
    created() {
        this.getFlowTemplate();
        this.setColumn();
        window.addEventListener('message', this.reloadPage, false);
        this.getTodoApplist();
    }
    switchSearch() {
        this.form.batchOperate = !this.form.batchOperate;
        this.reload();
    }
    agreeFlow() {
        this.showAgreeFlow = true;
    }
    agreeAllFlow() {
        this.cTable.onChangeCheckAll({ target: { checked: true } });
        this.showAgreeFlow = true;
    }
    hasFail(failInfo) {
        this.showAgreeFlowFail = true;
        this.failInfo = failInfo;
    }
    changeTable() {
        if (!this.cTable)
            return;
        const selectionRow = this.cTable.getSelectionRow();
        const tempData = selectionRow.map(item => {
            return item.id;
        });
        this.checkData = [...tempData];
    }
    hasCheck(val) {
        if (val) {
            const tempData = val.map(item => {
                return item.id;
            });
            this.checkData = [...tempData];
        }
    }
    expandSearch() {
        this.isExpand = !this.isExpand;
        const wrapperTableHeight = this.wrapperTable.offsetHeight;
        // 待办批量处理
        if (this.todoType === 1 && this.form.batchOperate) {
            this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71}px`;
            this.cTable.setScrollHeight();
        }
        if (this.todoType !== 3)
            return;
        this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71}px`;
        this.cTable.setScrollHeight();
    }
    deepCopy(value) {
        return JSON.parse(JSON.stringify(value));
    }
    async showSetField() {
        this.isShowSetField = true;
    }
    async reload() {
        await this.getTodoApplist();
        await this.resetSearch();
        await this.setColumn();
        this.$emit('reload');
        this.checkData = [];
        this.search();
    }
    onSearch() {
        const form = this.form;
        // 发起人
        const originator = form.originator[0] || {};
        // 当前处理人
        // const handlerPerson: any = form.handlerPerson[0] || {};
        // 发起时间
        const publishTime = form.publishTime || [];
        let startMomentTime = '';
        let endMomentTime = '';
        if (publishTime.length) {
            const startTime = publishTime[0] || {};
            const endTime = publishTime[1] || {};
            startMomentTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
            endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
        }
        const params = {
            instanceName: form.instanceName,
            moduleCode: form.moduleCode,
            startTime: startMomentTime,
            endTime: endMomentTime,
            instanceState: form.instanceState,
            originator: originator.id,
            batchOperate: form.batchOperate,
            appCode: form.batchOperat ? form.appCode : '',
        };
        this.search(params);
    }
    resetSearch() {
        const { batchOperate, appCode } = this.form;
        this.form = {
            instanceName: '',
            moduleCode: undefined,
            originator: [],
            publishTime: [],
            instanceState: undefined,
            handlerPerson: [],
            batchOperate,
            appCode,
        };
    }
    /**
     * 获取流程模板
     */
    getFlowTemplate() {
        FlowCenterService.getFlowTemplate({ todoType: this.todoType }).then((res) => {
            if (res.success) {
                this.flowTemplate = res.data || [];
            }
            else {
                this.flowTemplate = [];
            }
        });
    }
    async updateFlowData() {
        this.checkData = [];
        await this.resetSearch();
        await this.onSearch();
    }
    async toggleMenu(todoType) {
        if (todoType === this.todoType)
            return;
        this.isExpand = false;
        this.wrapperTable.style.height = this.wrapperTableHeight + 'px';
        this.resetSearch();
        this.$router.push({
            name: 'flow-center',
            query: {
                todoType: todoType,
            },
        });
    }
    async getHideField() {
        const res = await FlowCenterService.getHideField({
            todoType: this.todoType,
        });
        if (res.success) {
            const data = res.data || {};
            const hideFieldList = data.hideFields || [];
            if (!hideFieldList.length) {
                this.hideFieldList = this.defaultHideFieldList;
            }
            else {
                this.hideFieldList = hideFieldList;
            }
        }
    }
    async setColumn() {
        await this.getHideField();
        this.fieldColumns = [];
        for (const item of this.tableColumns) {
            if (!this.hideFieldList.includes(item.key)) {
                this.fieldColumns.push(item);
            }
        }
        // for (const item of this.fieldColumns) {
        //   if (item.key !== 'index') {
        //     item.width = `${96 / this.fieldColumns.length}%`;
        //   }
        // }
    }
    goPage(item) {
        const params = {
            appCode: item.appCode,
            appVersion: item.appVersion,
        };
        const winOpen = !this.isFreeLoginPlatform && window.open('', '_blank');
        WorkPlatformService.getAppUrl(params).then((res) => {
            if (res.success) {
                const pageArr = typeof item.pageJson === 'string' ? JSON.parse(item.pageJson) : [];
                let pageJson;
                pageArr.forEach((page) => {
                    // pageType:ALL,PC,MOBILE
                    if (page.pageType === 'PC') {
                        pageJson = page;
                    }
                });
                if (!pageJson) {
                    pageArr.forEach((page) => {
                        if (page.pageType === 'ALL') {
                            pageJson = page;
                        }
                    });
                }
                const query = {
                    groupCode: '',
                    pageGroupCode: '',
                    pageCode: '',
                    workItemId: item.id,
                    workflowInstanceId: item.instanceId,
                    appVersion: item.appVersion,
                    workflowCode: item.workflowCode,
                    ...pageJson,
                };
                const str = Object.entries(query)
                    .map((queryArr) => {
                    return queryArr.join('=');
                })
                    .join('&');
                let url = `${res.data}?${str}`;
                if (process.env.NODE_ENV !== 'production') {
                    url = `http://localhost${res.data.substring(res.data.indexOf('/api/launcher/'))}?${str}`;
                }
                // window.location.href = url;
                try {
                    if (this.ISWECHAT && isPhone()) {
                        const token = localStorage.getItem('token');
                        const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                        window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                    else if ((this.IsDingTalk || this.ISWECHAT)) {
                        const token = localStorage.getItem('token');
                        const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                        if (this.needOpenBrowser) {
                            service.openLink(`${url}&token=${token}&userInfo=${userInfo}`);
                        }
                        else {
                            window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                        }
                    }
                    else {
                        if (!winOpen) {
                            throw new Error('浏览器新开弹窗未开启');
                        }
                        setTimeout(() => {
                            winOpen.location = url;
                        }, 100);
                    }
                }
                catch (e) {
                    Vue.prototype.$errorcatcher.showMessagePopup({
                        title: '错误提示！',
                        message: [e, '!'],
                        displayFooter: false,
                        correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
                    });
                }
            }
        });
    }
    /**
     * 搜索
     */
    async search(searchParams = {}) {
        const params = {
            ...searchParams,
            todoType: this.todoType,
            page: 1,
            batchOperate: this.form.batchOperate,
            appCode: this.form.batchOperate ? this.form.appCode : '',
        };
        this.cTable.pagination.current = 1;
        this.cTable.getTableList(params);
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            todoType: this.todoType,
            page: params.page,
            batchOperate: this.form.batchOperate,
            appCode: this.form.batchOperate ? this.form.appCode : '',
        };
        const res = await FlowCenterService.todoSearch(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                // item.usedtime = this.formatSeconds(item.usedtime);
                item.instanceStateText = (this.flowStatus.find((f) => f.enName === item.instanceState) ||
                    {}).label;
            });
        }
        this.tableList = res.data;
        return res;
    }
    timeOut(value) {
        const oldTime = new Date(value);
        const newTime = new Date();
        console.log(oldTime, newTime, 'timeOut');
        const timeout = parseInt((newTime - oldTime) + '');
        return `已超时${this.formatSeconds(timeout)}`;
    }
    formatSeconds(value) {
        if (!value)
            return '--';
        let theTime = parseInt(value); // 需要转换的时间秒
        let theTime1 = 0; // 分
        let theTime2 = 0; // 小时
        let theTime3 = 0; // 天
        theTime = theTime / 1000;
        if (theTime > 60) {
            theTime1 = parseInt(String(theTime / 60));
            theTime = parseInt(String(theTime % 60));
            if (theTime1 > 60) {
                theTime2 = parseInt(String(theTime1 / 60));
                theTime1 = parseInt(String(theTime1 % 60));
                if (theTime2 > 24) {
                    // 大于24小时
                    theTime3 = parseInt(String(theTime2 / 24));
                    theTime2 = parseInt(String(theTime2 % 24));
                }
            }
        }
        let result = '';
        if (theTime > 0) {
            result = '' + parseInt(theTime + '') + '秒';
        }
        if (theTime1 > 0) {
            result = '' + parseInt(theTime1 + '') + '分' + result;
        }
        if (theTime2 > 0) {
            result = '' + parseInt(theTime2 + '') + '小时' + result;
        }
        if (theTime3 > 0) {
            result = '' + parseInt(theTime3 + '') + '天' + result;
        }
        return result;
    }
    beforeDestroy() {
        window.removeEventListener('message', () => { });
    }
    /**
     * 如果流程发生变化，则刷新数据
     */
    reloadPage(evt) {
        if (evt.data === 'reload' && evt.origin === window.location.origin) {
            this.onSearch();
            this.$emit('reload');
        }
    }
    handleClickRow(record) {
        this.goPage(record);
    }
    /**
     * 获取待办应用列表
     */
    async getTodoApplist() {
        const params = {
            batchOperate: true,
            onlineApp: true,
            todoType: 1,
        };
        const res = await FlowCenterService.getAppList(params);
        if (res.success) {
            this.appList = res.data || [];
            if (this.appList.length > 0) {
                this.form.appCode = this.appList[0].appCode;
            }
            else {
                this.form.appCode = '';
            }
        }
    }
};
__decorate([
    Ref()
], FlowCenterComponent.prototype, "wrapperTable", void 0);
__decorate([
    Ref()
], FlowCenterComponent.prototype, "cTable", void 0);
__decorate([
    Global.State('needOpenBrowser')
], FlowCenterComponent.prototype, "needOpenBrowser", void 0);
__decorate([
    Watch('todoType')
], FlowCenterComponent.prototype, "onTodoTypeChange", null);
FlowCenterComponent = __decorate([
    Component({
        name: 'FlowCenterComponent',
        components: {
            CTable,
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
            SetField,
            agreeFlow,
            agreeFlowFail,
        },
    })
], FlowCenterComponent);
export default FlowCenterComponent;
