import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { Upload, Icon } from '@h3/antd-vue';
let Uploader = class Uploader extends Vue {
    constructor() {
        super(...arguments);
        this.valueSync = [];
        this.filesList = [];
        this.isError = false; // 清除文件列表
        this.onceTime = false; // 弹窗提示一次
        this.maxFileLength = false; // 是否有上传过的值 并且大于0
    }
    onValueChange() {
        const list = this.valueSync || [];
        if (Array.isArray(list)) {
            list.forEach((item) => {
                if (item.status) {
                    if (item.response) {
                        // 上传 没提交数据
                        item.url = '/api/file/download?refId=' + item.response.data.refId;
                    }
                    else {
                        // 后台返回数据 优先取refId旧数据只有id
                        item.url = '/api/file/download?refId=' + (item.refId || item.id);
                    }
                }
            });
        }
        this.filesList = list;
    }
    get headers() {
        return {
            Authorization: 'Bearer ' + localStorage.getItem('token') || '',
        };
    }
    remove() {
        return true;
    }
    async preview(file) {
        const type = file.type || file.mimeType;
        const supportPreview = 'image/';
        if (!type || !type.startsWith(supportPreview)) {
            window.open(file.url);
            return;
        }
        if (file.unPreview)
            return;
        let src;
        if (!file.url && !file.preview) {
            src = await this.getBase64(file.originFileObj);
        }
        else {
            const prefix = file.url || file.preview;
            src = `${location.origin}${prefix}`;
        }
        const win = window.open();
        const myImg = win.document.createElement('img');
        myImg.src = src;
        win.document.body.appendChild(myImg);
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    onchange(info) {
        console.log('on upload.vue changed');
        let { file, fileList } = info;
        if (fileList && fileList.length > 0) {
            fileList = fileList.filter((f) => f.uid || f.id); // fileList模式 remove 只会删除uid
            fileList.forEach((item) => {
                if (item.response) {
                    if (!item.response.success &&
                        (item.response.errCode !== '200' || item.response.errCode !== 200)) {
                        item.status = 'error';
                    }
                }
            });
        }
        const status = file && file.status ? file.status !== 'removed' : true;
        if (this.isError && status) {
            if (!this.maxFileLength) {
                fileList = [];
            }
            else {
                fileList = this.valueSync;
            }
        }
        this.valueSync = fileList;
        this.$emit('change', info.file, fileList, info.event);
    }
    beforeUpload(file, fileList) {
        // const this = this;
        const { type } = file;
        this.isError = false;
        this.maxFileLength = false;
        const imagesList = [
            'image/jpeg',
            'image/png',
            'image/jpg',
            'image/bmp',
            'image/gif',
            'image/tif',
            'image/svg',
        ];
        const filesList = [
            'text/xml',
            'text/plain',
            'video/mp4',
            'video/mp3',
            'video/avi',
            'video/wmv',
            'application/pdf',
            'application/zip',
            'application/msword',
            'application/x-msdownload',
            'application/vnd.ms-excel',
            'application/x-zip-compressed',
            'application/x-rar-compressed',
            'application/x-chrome-extension',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        let supportList = [];
        supportList = [...imagesList, ...filesList];
        // 非文件夹
        const isLt2M = file.size / 1024 / 1024 < parseInt(this.defaultUploadProps.uploadSize);
        const isSupport = supportList.find((item) => item === type);
        let fileLength = 0;
        if (Array.isArray(this.valueSync) && this.valueSync.length) {
            fileLength = this.valueSync.length;
        }
        if (!isLt2M) {
            this.isError = true;
            if (!this.onceTime) {
                this.onceTime = true;
                this.$message.error(this.defaultUploadProps.maxLengthError, 1.5, () => {
                    this.onceTime = false;
                });
            }
            return false;
        }
        else if (!isSupport) {
            this.isError = true;
            // this.$message.error('文件格式不对，仅支持上传文档/图片/压缩等格式的文件!');
            if (!this.onceTime) {
                this.onceTime = true;
                this.$message.error('文件格式不对，仅支持上传文档/图片/压缩等格式的文件!', 2, () => {
                    this.onceTime = false;
                });
                if (fileLength > 0) {
                    this.maxFileLength = true;
                }
            }
            return false;
        }
        else if (fileList.length + fileLength >
            this.defaultUploadProps.maxLength) {
            if (!this.onceTime) {
                this.onceTime = true;
                this.$message.error(`最大上传数${this.defaultUploadProps.maxLength}。`, 1.5, () => {
                    this.onceTime = false;
                });
            }
            if (fileLength > 0) {
                this.maxFileLength = true;
            }
            this.isError = true;
            return false;
        }
    }
};
__decorate([
    Prop({
        default: () => ({}),
    })
], Uploader.prototype, "defaultUploadProps", void 0);
__decorate([
    Watch('valueSync', {
        immediate: true,
    })
], Uploader.prototype, "onValueChange", null);
Uploader = __decorate([
    Component({
        name: 'Uploader',
        components: {
            AUpload: Upload,
            AIcon: Icon,
        },
    })
], Uploader);
export default Uploader;
