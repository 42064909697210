import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue, Watch, Ref, } from 'vue-property-decorator';
import { Modal } from '@h3/antd-vue';
let agreeFlowFail = class agreeFlowFail extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.initTableData = [];
        this.tableColumns = [
            {
                dataIndex: 'instanceName',
                key: 'instanceName',
                title: '流程名称',
                width: 220,
                slots: { title: 'instanceName', name: '流程名称' },
                scopedSlots: { customRender: 'instanceName' },
            },
            {
                dataIndex: 'originatorName',
                key: 'originatorName',
                title: '发起人',
                width: 130,
                disabledSetField: true,
                ellipsis: true,
                slots: { title: 'originatorName', name: '发起人' },
            },
            {
                dataIndex: 'reason',
                key: 'reason',
                title: '失败原因',
                ellipsis: true,
                slots: { title: 'reason', name: '失败原因' },
            },
        ];
    }
    visibleChange(v) {
        if (Object.keys(v).length) {
            this.initTableData = v.failResults;
        }
    }
    handleCancel() {
        this.$emit('update:isVisible', false);
        this.$emit('reload');
    }
};
__decorate([
    PropSync('isVisible', { default: false })
], agreeFlowFail.prototype, "visible", void 0);
__decorate([
    Ref()
], agreeFlowFail.prototype, "Approve", void 0);
__decorate([
    Prop({ default: () => ({}) })
], agreeFlowFail.prototype, "failInfo", void 0);
__decorate([
    Watch('failInfo', { immediate: true })
], agreeFlowFail.prototype, "visibleChange", null);
agreeFlowFail = __decorate([
    Component({
        name: 'agreeFlowFail',
        components: {
            AModal: Modal,
        },
    })
], agreeFlowFail);
export default agreeFlowFail;
