import { __decorate } from "tslib";
import { Component, Vue, Ref, Prop, Watch, } from 'vue-property-decorator';
import { FlowService } from '@/sevices';
import CreateEntrust from '@/components/flow-manage/create-entrust/index.vue';
import Perm from '@/router/permission';
import { CTable } from '@cloudpivot-hermes/common-components';
let FlowEntrustComponent = class FlowEntrustComponent extends Vue {
    constructor() {
        super(...arguments);
        this.isShowCreateFlowEntrust = false;
        this.operateType = 1; // 1: 新增 2：编辑
        this.editData = {};
        this.checkTableData = [];
        this.searchParams = {};
        this.isFilterExpired = false;
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'consignorName',
                key: 'consignorName',
                ellipsis: true,
                slots: { title: 'consignorNameTitle', name: '委托人' },
            },
            {
                dataIndex: 'mandataryName',
                key: 'mandataryName',
                ellipsis: true,
                slots: { title: 'mandataryNameTitle', name: '被委托人' },
            },
            {
                dataIndex: 'entrustTime',
                key: 'entrustTime',
                ellipsis: true,
                slots: { title: 'entrustTimeTitle', name: '受托期限' },
                scopedSlots: { customRender: 'entrustTime' },
            },
            {
                width: 100,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    onFlowCenterChange() {
        if (this.isFlowCenter) {
            this.tableColumns.splice(1, 1);
        }
    }
    get isSuperAdmin() {
        const res = Perm.isSuperAdmin();
        return res;
    }
    get checkedTableList() {
        return !this.checkTableData.length;
    }
    showCreateEntrust() {
        this.isShowCreateFlowEntrust = true;
        this.operateType = 1;
    }
    changeTable() {
        if (this.CTable) {
            this.checkTableData = this.CTable.getSelectionRow();
        }
    }
    reload() {
        this.search();
    }
    /**
     * 搜索
     */
    search(searchParams = {}) {
        this.searchParams = searchParams;
        this.CTable.pagination.current = 1;
        this.CTable.pagination.pageSize = 20;
        this.CTable.getTableList();
    }
    filterExpired() {
        this.search();
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            ...this.searchParams,
            page: params.page,
            isFilterExpired: this.isFilterExpired,
        };
        if (this.isFlowCenter) {
            p.isCurrentUser = true;
        }
        const res = await FlowService.getFlowEntrustList(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.entrustTime = `${item.entrustStartTime}~${item.entrustEndTime}`;
                let entrustTypeName = '';
                if (item.entrustType === 1) {
                    entrustTypeName = '流程审批';
                }
                else if (item.entrustType === 2) {
                    entrustTypeName = '流程发起';
                }
                item.entrustTypeName = entrustTypeName;
            });
        }
        return res;
    }
    editHandler(record) {
        this.editData = record;
        this.isShowCreateFlowEntrust = true;
        this.operateType = 2;
    }
    delHandler(record) {
        const okOk = () => {
            const params = [record.id];
            FlowService.delEntrust(params).then(() => {
                this.$message.success('删除成功！');
                this.reload();
            });
        };
        this.$confirm({
            title: '确定要删除这条信息吗？',
            content: '删除后，该数据将被全部删除且无法恢复，确认删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: okOk,
        });
    }
    delBath() {
        const okOk = () => {
            const params = this.checkTableData.map((item) => {
                return item.id;
            });
            FlowService.delEntrust(params).then(() => {
                this.$message.success('删除成功！');
                this.reload();
            });
        };
        this.$confirm({
            title: '确定要删除已选中信息吗？',
            content: '删除后，数据将被全部删除且无法恢复，确认删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: okOk,
        });
    }
};
__decorate([
    Ref()
], FlowEntrustComponent.prototype, "CTable", void 0);
__decorate([
    Prop({ default: false })
], FlowEntrustComponent.prototype, "isFlowCenter", void 0);
__decorate([
    Watch('isFlowCenter', { immediate: true })
], FlowEntrustComponent.prototype, "onFlowCenterChange", null);
FlowEntrustComponent = __decorate([
    Component({
        name: 'FlowEntrustComponent',
        components: {
            CTable,
            CreateEntrust,
        },
    })
], FlowEntrustComponent);
export default FlowEntrustComponent;
