/**
 * 集成操作树的工具类
 */
export default class TreeUtils {
    /**
     * 通过指定的key获取树节点信息
     * @param value // 键值
     * @param key   // 键名
     * @param treeArr   // 树数组
     * @return 返回树节点信息
     */
    static getTreeNodeStateByKey(value, key, treeArr) {
        let isStop = false;
        const nodePos = [];
        let nodeState;
        const findTreeNodeState = function (val, k, array) {
            for (let i = 0, len = array.length; i < len; i++) {
                nodePos.push(i);
                const item = array[i];
                if (val === item[k]) {
                    isStop = true;
                    nodeState = item;
                    return;
                }
                const children = item.children;
                if (children && children.length) {
                    findTreeNodeState(val, k, children);
                }
                if (isStop)
                    return;
                nodePos.pop();
            }
        };
        findTreeNodeState(value, key, treeArr);
        return { nodeState, nodePos: `0-${nodePos.join('-')}` };
    }
    /**
     * 遍历树
     * @param tree
     * @param callback
     */
    static forEachTree(tree, callback) {
        const forEachTree = (t, c) => {
            for (let i = 0, len = t.length; i < len; i++) {
                const item = t[i];
                c(item);
                if (item.children && item.children.length) {
                    forEachTree(item.children, callback);
                }
            }
        };
        forEachTree(tree, callback);
    }
    /**
     * 通过位置来获取树节点信息
     * @param treeArr 树数组
     * @param nodePos 位置
     * @return 返回树节点信息
     */
    static getTreeNodeStateByPos(treeArr, nodePos) {
        const pos = nodePos?.split('-').slice(1);
        let resultNode = {};
        let tree = treeArr;
        for (let i = 0, len = pos.length; i < len; i++) {
            resultNode = tree[Number(pos[i])];
            if (i < len) {
                tree = resultNode?.children;
            }
        }
        return resultNode;
    }
    /**
     * 根据位置获取dom元素
     */
    static getItemHtmlByPos(pos, treeDom) {
        const className = `.node-${pos}`;
        const dom = treeDom.querySelector(className);
        return dom;
    }
    /**
     * 获取指定标签名的第一个父节点
     * @param dom
     * @param target
     */
    static getParentItemHtml(dom, target) {
        let htmlDom = dom;
        while (true) {
            const tagName = htmlDom.tagName.toLowerCase();
            const targetName = target.toLowerCase();
            if (tagName === targetName) {
                break;
            }
            else {
                htmlDom = htmlDom.parentElement;
            }
        }
        return htmlDom;
    }
    /**
     * 获取dom元素距离滚动条元素的位置
     */
    static getItemOffsetTop(itemHtml, treeDom) {
        let dom = itemHtml;
        let offsetTop = 0;
        while (dom) {
            if (treeDom.className === dom.className) {
                break;
            }
            else {
                offsetTop += dom.offsetTop;
                dom = dom.offsetParent;
            }
        }
        ;
        return offsetTop;
    }
}
